import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import BigText from "../components/text/bigtext"

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40vh;
  flex-direction: column;
`

class NotFoundPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <BigText text={"not found"} mb="3vw"></BigText>
        <Link to="/">
          <svg
            fill={"black"}
            viewBox="0 0 24 24"
            width="6vw"
            height="6vw"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>
        </Link>
      </Wrapper>
    )
  }
}

export default NotFoundPage
