import React from "react"
import styled from "@emotion/styled"

const Text = styled("p")`
  font-family: "Helvetica Neue", sans-serif;
  font-size: ${props => (props.fontSize ? props.fontSize : "4.5vw")};
  margin-bottom: ${props => (props.mb ? props.mb : null)};
  margin-top: ${props => (props.mt ? props.mt : null)};
  line-height: 1;
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  color: ${props => (props.color ? props.color : "black")};

  @media screen and (max-width: 1025px) {
    font-size: 42px;
    line-height: 1.2;
  }
  @media screen and (max-width: 577px) {
    font-size: 28px;
    line-height: 1.2;
  }
`

const BigText = ({ text, ...props }) => (
  <Text
    {...props}
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  ></Text>
)

export default BigText
